<template>
    <html>
    <!-- Empty store shows if there are no questions -->
    <div v-if="wait" class="posts-empty mt-3">
        <div class="posts-empty-inner">
            <h4>Please wait...</h4>
            <h6>Loading survey details, please wait...</h6>
        </div>
    </div>

    <div v-else>
        <div class="container-lg mt-3 mt-md-5 mb-3 mb-md-5">
            <Alerts v-if="success" :_success="success"></Alerts>
            <!-- Empty store shows if there are no questions -->
            <div v-if="error" class="posts-empty mt-3">
                <div class="posts-empty-inner">
                    <div class="posts-empty-inner_img">
                        <img loading="lazy" src="@/assets/img/error-message.png" width="80" height="80"
                             alt="Error message"/>
                    </div>
                    <h4>Survey could not be loaded</h4>
                    <h6>{{ error }}</h6>
                </div>
            </div>

            <div v-if="survey && !error">
                <div class="preview-title">
                    <h1>{{ survey.title }}</h1>
                </div>
                <div class="preview">
                    <Questionnaire :respond="true" v-if="survey" :survey="survey"></Questionnaire>

                    <div class="text-muted mt-5">
                        Want to create awesome surveys like this one? <br>
                        Visit <a href="https://client.sabasi.mobi">https://client.sabasi.mobi</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </html>
</template>

<script>
import Questionnaire from "../Views/Helpers/Questionnaire";
import Alerts from "../Views/Helpers/Alerts";

export default {
    name: "Fill",
    components: {Alerts, Questionnaire},
    props: ['code'],
    data() {
        return {
            survey: null
        }
    },
    mounted() {
        this.loadSurvey()
    },
    computed: {
        userId() {
            let user = JSON.parse(localStorage.getItem(process.env.VUE_APP_NAME + '_user'))
            if (user) {
                return user.id
            }
            return null
        },
    },
    methods: {
        loadSurvey() {
            this.wait = true
            this.success = "Loading survey. Please wait..."
            this.$http.get(`/api/survey/fill/${this.code}?user_id=` + this.userId).then(response => {
                this.survey = response.data.data
                this.success = "Thank you for participating in this survey."
                setTimeout(() => (this.success = ''), 100)
            }).catch(error => {
                this.showError(error)
            }).then(() => {
                this.wait = false
            })
        }
    }
}
</script>

<style scoped>

</style>
